.sidebar {
  .burger {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 35px;
    height: 35px;
  }

  .panel {
    z-index: 2;
    left: 0;
    position: fixed;
    height: 100%;
    background: var(--background-color);
    border-right: 1px solid var(--border-color);
    overflow-x: hidden;
    transition: 1.2s;
    width: 245px;

    .close-btn {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 10px;
      padding-top: 10px;
    }

    .close-btn svg {
      width: 35px;
      height: 35px;
    }

    .toggle-buttons {
      margin-top: 35px;
      margin-bottom: 35px;
      display: flex;
      justify-content: space-around;
    }

    .navbar {
      flex-direction: column;
      height: fit-content;

      .link {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (min-width: 1000px) {
    display: none;
  }
}
