@use "./variables.scss" as *;

body.dark {
  --background-color: #{$dark-background-color};
  --text-color: #{$dark-text-color};
  --link-color: #{$dark-link-color};
  --border-color: #{$dark-border-color};
  --brigthness: #{$dark-brightness};
  transition: background 1.2s;
}

body {
  --background-color: #{$light-background-color};
  --text-color: #{$light-text-color};
  --link-color: #{$light-link-color};
  --border-color: #{$light-border-color};
  --brigthness: #{$light-brightness};
  transition: background 1.2s;

  @media screen and (min-width: 1000px) {
    --body-side-padding: 12%;
    --regular-font-size: 1.4em;
    --smaller-font-size: 0.5em;
    --titles-font-size: 1.5em;
    --titles-subcard-font-size: 1.2em;
    --subtitles-font-size: 1em;
  }

  @media screen and (max-width: 1000px) {
    --body-side-padding: 0%;
    --regular-font-size: 1.2em;
    --smaller-font-size: 0.5em;
    --titles-font-size: 1.3em;
    --titles-subcard-font-size: 1.2em;
    --subtitles-font-size: 1em;
  }
}

body {
  margin: 0;
  display: flex;
  background-color: var(--background-color);
  flex-direction: column;
  align-items: center;
  font-size: var(--regular-font-size);
  color: var(--text-color);
  min-height: $body-height;
  font-family: "Mulish", sans-serif;
}

.body {
  padding-top: $body-top-padding;
  padding-left: var(--body-side-padding);
  padding-right: var(--body-side-padding);
  min-height: $body-height;
  display: flex;
  flex-direction: column;
}

a {
  color: var(--link-color);
}

#root {
  width: 100%;
}

.content {
  margin-top: 8%;
  margin-left: 10%;
  margin-right: 10%;
}

.italic {
  font-style: italic;
}

.centered {
  text-align: center;
}
