$body-top-padding: 30px;
$body-height: calc(100vh - $body-top-padding);

// dark-mode
$dark-background-color: #222429;
$dark-text-color: #e6e6e6;
$dark-link-color: #61dafb;
$dark-border-color: lightgrey;
$dark-brightness: 70%;

// light-mode
$light-background-color: #ffffff;
$light-text-color: #333333;
$light-link-color: #2c7e8f;
$light-border-color: #b3b3b3;
$light-brightness: 100%;

// font size for Julius
$font-titles: "Julius Sans One", sans-serif;
