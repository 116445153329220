.navbar {
  height: 85px;
  display: flex;
  justify-content: center;
  z-index: 12;

  .link {
    color: var(--text-color);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-family: "Julius Sans One", sans-serif;

    &.active {
      text-decoration: underline solid var(--text-color) 2px;
    }
  }
}
