@use "../../../variables.scss" as *;

.toggle-btn {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  overflow: hidden;
  border-radius: 100px;

  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .layer {
    border-radius: 100px;
    width: 100%;
    background-color: #ebf7fc;
    filter: brightness(var(--brigthness));
    transition: 0.3s ease all;
    z-index: 1;
  }

  .knobs-before {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 10px;
    color: $light-text-color;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #36e4f4;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  }

  .checked {
    left: 42px;
    background-color: #03a9f4;
    color: $dark-text-color;
  }

  .checkbox:checked ~ .layer {
    background-color: #d2f2f5;
    filter: brightness(var(--brigthness));
  }

  .knobs,
  .knobs-before,
  .layer {
    transition: 0.3s ease all;
  }
}
