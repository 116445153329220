@use "../../variables.scss" as *;

#maintenance {
  font-family: $font-titles;
  text-align: center;
}

#maintenance img {
  margin-top: 2%;
  width: 20%;
}

#maintenance p {
  margin-top: 15%;
}
