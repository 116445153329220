@use "../../../variables.scss" as *;

.footer {
  margin-top: auto;
  margin-bottom: 20px;
}

.footer p {
  margin-bottom: 0;
  font-size: var(--smaller-font-size);
}
